body {
  padding-top: 54px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px !important;
}

@media (min-width: 992px) {
  body {
    padding-top: 56px;
  }
}

.card {
  height: 100%;
}

footer {
  display: block;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem!important;
}

.bg-dark {
  background-color: #343a40!important;
}

.text-white {
  color: #fff!important;
}

img {
  vertical-align: middle;
  border-style: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.h4, h4 {
  font-size: 22px!important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.row {
  display: flex;
}

.float-right {
  float: right;
}

.logo {
  padding-top: 5px !important;
}

.jumbotron {
  background-color: #eef6d0 !important;
}

.jumbotron p {
  margin-bottom: 0 !important;
}

.container {
  padding-left: 0 !important;
}

.contacts h1 {
  margin-top: 0 !important;
}

.contacts .lead {
  padding-top: 10px;
  font-weight: bold;
}

.contacts .contact-title {
  text-align: right;
}

.contacts .inset {
  padding-left: 50px;
}
